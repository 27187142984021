<template>
  <div class="box-layout">
    <HeaderFiveEpitonet>
      <img slot="logo" src="../../assets/img/logo/global-logo.jpg" />
    </HeaderFiveEpitonet>

    <!-- Start Portfolio Details Area -->
    <div class="rf-portfolio-details section-ptb-xl">
      <v-container class="container">
        <v-row>
          <v-col sm="6" cols="12" class="pr--60 pr-md-15 pr-sm-15">
            <div class="rp-port-thumb">
              <div class="port-single-thumb">
                <img
                  src="../../assets/img/567x600px/567x600px-Aram.jpeg"
                  alt="portfolio"
                />
              </div>
            </div>
          </v-col>
          <v-col sm="6" cols="12">
            <div class="rp-port-details-inner sticky-top">
              <div class="content">
                <h3>Gyenge és erős áramú szakipari munkák</h3>
                <p>
                  Az irodai környezetben végzett munkálatok esetén minden esetben igyekszünk kiválasztani azokat a megoldásokat, amelyek esztétikai szempontból is megfelelőek egy ilyen környezet számára. Ha az irodai rendszer fejlesztése együttesen történik az iroda adat-, hang vagy videóhálózatának fejlesztésével, akkor ezeket a tevékenységeinket összehangoltan végezzük, hogy a legminimálisabb akadályoztatás történjen az irodai tevékenységek végrehajtásában. 
 
Ipari környezetben a tartós, robosztus és biztonságos megoldásokra törekszünk. Kábelcsatornáinkat, kapcsolószekrényeinket úgy válasszuk ki az ilyen esetekben, hogy azok a tartós, hosszútávú használat követelményeinek megfeleljenek. 

Nagy gyakorlattal rendelkező szakembereinkkel a jó minőségű és hatékony kivitelezéssel biztosítjuk az építmények és technológiai rendszerek, lehető legrövidebb idő alatt és optimális beruházási költséggel történő megvalósítását. 
                </p>
                <h4>Villanyoszlop telepítése:</h4>
                <p>

                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area -->

    <Footer />
  </div>
</template>

<script>
  import HeaderFiveEpitonet from "../../components/header/HeaderFourEpitonet";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      HeaderFiveEpitonet,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .portfolio-details-page .-rn-footer {
    background: #f9f9f9;
  }
</style>
